export const theme = {
  headerHeightBig: 80,
  mobileMenu: '950px',
  textDark1: '#232e27',
  textMedium1: "#7C7C80",
  dark1: '#576b4e',
  mediumDark1: '#8c9c09',
  medium1: '#8db4c6',
  mediumLight1: '#e2e6e4',
  light1: '#b3d6ff',
  radialGradientLight: "radial-gradient(circle, white 33%, rgba(139, 156, 9, 0.05) 66%)", //#8c9c09
  coolWhite: '#FFFFFB',
  warmWhite: '#F9F9F2',
  offWhite: '#F5F5F5',
  orange: {
    bg: '#ffe7d1',
    text: '#8c4400'
  },
  red: {
    bg: '#ffd1d1',
    text: '#750000'
  },
  green: {
    bg: '#d1ffd3',
    text: '#007006'
  },
  blue: {
    bg: '#d1e8ff',
    text: '#003870'
  },
  // offWhite: {
  //   bg: '#f7f7f7',
  //   text: 'lightgrey'
  // },
  grey: {
    bg: 'grey',
    text: 'lightgrey'
  },
  davysGrey: (opacity = 1) => `rgba(72,78,81,${opacity})`,
  babyPowder: (opacity = 1) => `rgba(255,255,251,${opacity})`,
  wildBlueYonder: (opacity = 1) => `rgba(159,181,219,${opacity})`,
  yellowGreenCrayola: (opacity = 1) => `rgba(169,214,125,${opacity})`,
  lightCoral: (opacity = 1) => `rgba(229, 125, 121,${opacity})`,
  jet: (opacity = 1) => `rgba(43, 47, 48,${opacity})`,
  platinum: (opacity = 1) => `rgba(227, 227, 222,${opacity})`,
  littleBoyBlue: (opacity = 1) => `rgba(123, 158, 219,${opacity})`,
  greenRYB: (opacity = 1) => `rgba(115, 176, 55,${opacity})`,
  fireOpal: (opacity = 1) => `rgba(230, 85, 80,${opacity})`,
  logoYellow: (opacity = 1) => `rgb(219,222,147,${opacity})`
}

export const grommetTheme = {
  global: {
    colors: {
      brand: '#5085A5',
      focus: 'transparent'
    }
  },
  select: {
    background: 'white',
    step: 5,
    control: {
      extend: {
        width: '100%',
        height: '100%'
      }
    }
  }
}

import React, {useContext} from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { LayoutContext } from '../layout';

const NavLink = styled(Link)`
  display: flex;
  position: relative;
  align-items: center;
  line-height: 0px;
  justify-content: center;
  padding: 0px 20px;
  /* color: ${({ theme }) => theme.davysGrey()}; */
  color: white;
  font-size: inherit;
  background-color: inherit;
  cursor: pointer;
  white-space: nowrap;
  ${({ large }) =>
      large
        ? css`
            font-size: 3em;
          `
        : null}
  ${({ highlite }) =>
      highlite
        ? css`
            color: lightcoral;
          `
        : null}
  transition: color 0.3s linear;
  ${({active, theme}) => active? css`color: ${theme.greenRYB()};`: null}
  /* &.active {
    color: ${({ theme }) => theme.greenRYB()};
  } */
  &:hover {
    color: ${({ theme }) => theme.littleBoyBlue()};
  }
  @media (max-width: ${({ theme }) => theme.mobileMenu}) {
    color: ${({theme}) => theme.davysGrey()};
    ${({active, theme}) => active? css`color: ${theme.greenRYB()};`: null}
    text-align: center;
    padding: 20px 0px;
    border-bottom: 1px solid lightgrey;
    &:first-child {
      border-top: 1px solid lightgrey;
    }
  }
`;


const MainNavItems = ({ setNavOpen, navOpen, navData }) => {
  const { location } = useContext(LayoutContext)

  return navData.map((navItem, idx) => {
    return (
      <NavLink
        key={idx}
        to={navItem.link}
        // highlite={navItem.text === 'Admin'}
        // large={navItem.id === 'MyProfile'}
        exact
        active={location.hash? `${location.pathname}${location.hash}` === navItem.link: location.pathname === navItem.link }
        onClick={() => {
          if (navOpen) {
            setNavOpen(false);
          }
        }}
      >
        <span style={{fontSize: '2em'}}>{navItem.icon}</span>&nbsp;&nbsp;{navItem.text}
      </NavLink>
    );
  });
};

export default MainNavItems;

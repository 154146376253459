import React, {useEffect, useState} from "react"
import styled, {css, keyframes} from "styled-components"
// import Img from "gatsby-image"
// import { DataContext } from "../layout"
import { Link } from 'gatsby';

// import {ReactComponent as Logo} from '../../../images/logo_optimised.svg'
import ColorBlocks from '../../svg/colorBlocks.svg'
import Toc from '../../svg/touchOfClassWhite.svg'
// import Logo from '../../../images/web_logo.png'
// import { StaticImage } from "gatsby-plugin-image"

const TitleCont = styled(Link)`
  display: flex;
  flex-flow: column nowrap;
  /* width: 200px; */
  justify-content: center;
  align-items: center;
  /* margin-left: 20px; */
  cursor: pointer;
  color: #84986b;
  flex-shrink: 0;
  height: 100%;
`
export const StColorBlocks = styled(ColorBlocks)`
  /* height: ${({theme}) => theme.headerHeightBig}px; */
  /* width: 100px; */
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    }
`
export const StToc = styled(Toc)`
  ${({big}) => big? null: css`display: none;`}
  animation: ${fadeIn} 0.5s ease-in-out;
`

const Title = ({big}) => {
  const [displayLogo, setDisplayLogo] = useState(false)
  // const {title, logo} = useContext(DataContext)
  useEffect(() => {
    if (big) {
      setTimeout(() => setDisplayLogo(true), 200)
    } else {
      setDisplayLogo(false)
    }
  }, [big])

  return (
    <TitleCont to="/">
      {/* <StaticImage style={{maxHeight: '100%'}} objectFit='contain' src="../../images/logo.png" alt="ShareScreen Logo"/> */}
      <StToc big={displayLogo} style={{marginBottom: 10}} width="180px" preserveAspectRatio="xMidYMid meet"/>
      <StColorBlocks width="180px" preserveAspectRatio="xMidYMid meet"/>
      {/* <Img fixed={logo} alt={title} title={title}/> */}
      {/* <h1>{title}</h1> */}
    </TitleCont>
  )
}

export default Title

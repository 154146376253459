import React from 'react';
import styled from 'styled-components';
import { ColInSection } from './layoutComponents';
// import { StLogo } from "./Header/Title";
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import ColorBlocks from '../svg/colorBlocks.svg'
import Toc from '../svg/touchOfClassWhite.svg'

const FooterContainer = styled.footer`
  position: absolute;
  box-sizing: border-box;
  top: 100%;
  left: 0px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100vw;
  background-color: ${({ theme }) => theme.davysGrey()};
  padding: ${(props) => props.padding || '20px 0px 0px 0px'};
  & * {
    color: ${({ theme }) => theme.platinum()};
  }
`;

const Ul = styled.ul`
  @media only screen and (orientation: portrait) {
    min-width: 80%;
  }
`;

const Li = styled.li`
  list-style: none;
`;

const FooterColItem = ({ children }) => (
  <ColInSection col={4} color='textDark1' colFlex fontSize='0.9em'>
    {children}
  </ColInSection>
);

const Footer = () => {
  return (
    <FooterContainer padding='20px 5vw' color='medium1'>
      <FooterColItem></FooterColItem>
      <FooterColItem>
        <h4>LINKS</h4>
        <Ul>
          <Li>
            <Link to='#'>Home</Link>
          </Li>
          <Li>
            <Link to='#accommodation'>Accommodation</Link>
          </Li>
          <Li>
            <Link to='#attractions'>Attractions</Link>
          </Li>
          <Li>
            <Link to='#contact'>Contact us</Link>
          </Li>
        </Ul>
      </FooterColItem>
      <FooterColItem>
        <Toc/>
        <ColorBlocks style={{marginTop: 10}}/>
        <Ul>
          <Li>Touch of Class Guesthouse ©&nbsp;{new Date().getFullYear()}</Li>
          <Li>
            <a href='https://pieterwolmarans.dev' style={{textDecoration: 'underline'}}>
              Website by Pieter Wolmarans
            </a>
          </Li>
        </Ul>
      </FooterColItem>
      <FooterColItem></FooterColItem>
    </FooterContainer>
  );
};

export { Footer };

import { RiHomeLine } from 'react-icons/ri';
import {BiHotel, BiCameraMovie} from 'react-icons/bi';
import {AiOutlinePhone} from 'react-icons/ai';
import React from 'react';

const navData = [
  {
    text: 'Home',
    id: 'Home',
    link: '/',
    icon: <RiHomeLine />,
  },
  {
    text: 'Accommodation',
    id: 'Accommodation',
    link: '/#accommodation',
    icon: <BiHotel />,
  },
  {
    text: 'Attractions',
    id: 'Attractions',
    link: '/#attractions',
    // link: '#',
    icon: <BiCameraMovie />,
  },
  {
    text: 'Contact us',
    id: 'Contact',
    link: '/#contact',
    icon: <AiOutlinePhone />,
  },
  // {
  //   text: 'Sign Out',
  //   id: 'Sign Out',
  //   icon: <AiOutlineLogout />,
  // },
  // {
  //   text: '',
  //   id: 'MyProfile',
  //   link: '#',
  //   icon: <FaUserCircle />,
  // }
];

export default navData;

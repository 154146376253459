import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles/theme';
import '@fontsource/montserrat';
import '@fontsource/sacramento';
import '@fontsource/gowun-dodum';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "react-whatsapp-widget/dist/index.css";
import { graphql, useStaticQuery } from 'gatsby';

export const Context = React.createContext();

const UNITS_QUERY = graphql`
  query MyQuery {
    allContentfulTocUnit(sort: { fields: unitNumber }) {
      nodes {
        unitNumber
        id
        kingOrTwinBeds
        maxAdults
        name
        queenBeds
        rate
        roomFeatures
        singleBeds
        sleeperCouches
        slug
        unitType
        description {
          description
        }
        images {
          title
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    allContentfulAsset(filter: { title: { regex: "/.(featured)$/" } }) {
      edges {
        node {
          title
          gatsbyImageData
        }
      }
    }
  }
`;

const RootElement = ({ children }) => {
  const [offset, setOffset] = useState(0);
  const data = useStaticQuery(UNITS_QUERY);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset);
      };
    }
  });

  return (
    <>
      <Context.Provider
        value={{
          offset: offset,
          unitsArr: data.allContentfulTocUnit.nodes,
          featuredImages: data.allContentfulAsset.edges,
        }}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </Context.Provider>
    </>
  );
};

export default RootElement;

import React, {useContext} from 'react';
import styled from 'styled-components';
import Header from './header/header';
import { Footer } from './footer';
import GlobalStyle from '../styles/globalStyle';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

const MainContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  /* padding: ${({ theme }) => theme.headerHeightBig}px 0px 0px; */
  font-family: montserrat;
  background-color: white;
`;

export const LayoutContext = React.createContext()

const Layout = ({ children, location }) => {

  // console.log(pathname, hash)

  return (
    <LayoutContext.Provider value={{location}}>
      <GlobalStyle />
      <MainContainer>
        {children}
        <Footer />
      </MainContainer>
      <Header />
    </LayoutContext.Provider>
  );
};

export default Layout;

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
    box-sizing: border-box;
    font-family: 'Gowun Dodum', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    margin: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  div, h1, h2, h3, h4, h5, h6, p, ul {
    color: ${({theme}) => theme.jet()};
    line-height: 2em;
  }
  ul {
    list-style-type: circle;
  }
`

export default GlobalStyle;
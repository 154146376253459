import React, { useContext, useState } from "react"
import styled, {css} from "styled-components"
import Title from "./title"
import MainNavContainer from "./mainNavContainer"
import { Context } from '../rootElement';

export const HeaderCont = styled.header`
  width: 100vw;
  /* background-color: ${({theme}) => theme.babyPowder(0.9)}; */
  background: linear-gradient(0deg, ${({theme}) => theme.jet(0)} 0%, ${({theme}) => theme.jet(0.9)} ${({big}) => big? '100%': '10%'});
  /* ${({big}) => big? null: css`background-color: rgba(0,0,0,0.8)`} */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 2;
  height: ${({theme}) => theme.headerHeightBig}px;
  top: 0;
  left: 0;
  padding: 0px 20px;
  box-sizing: border-box;
  /* border-bottom: 1px solid lightgrey; */
  & a {
    text-decoration: none;
  }
  transition: height 0.2s ease-in-out;
  @media (min-width: ${({ theme }) => theme.mobileMenu}) {
    padding: 0px 100px;
    height: ${({theme, big}) => big? theme.headerHeightBig: theme.headerHeightBig/2}px;
    &:hover {
      height: ${({theme}) => theme.headerHeightBig}px;
    }
  }
`

const Header = () => {
  const {offset} = useContext(Context)
  const [hover, setHover] = useState(false)
  return (
    <HeaderCont big={offset === 0} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Title big={offset === 0 || hover}/>
      <MainNavContainer/>
    </HeaderCont>
  )
}

export default Header